import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const NotFoundPage = (): JSX.Element => (
    <Layout>
        <Helmet title="StitchedPunks – Page Not Found" />
        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <h1>Page Not Found</h1>
                    <p>
                        This page cannot be found.. Please <Link to="#contact">contact us</Link> if you think this is an
                        error!
                    </p>
                </div>
            </section>
        </div>
    </Layout>
);

export default NotFoundPage;
